import React from "react";
import { useGetLeadApi } from "../../context/getAllLeadContext";

const Graph = ({ adminLead }) => {
  const { totalLead } = useGetLeadApi();
  return (
    <>
      <div className="medflick-home-box">
        <ul>
          <li>
            <div className="total-leads-box">
              <div className="leads-text">
                <h4>Total Leads</h4>
                <h2>
                  {totalLead ? totalLead : adminLead} <span>+8%</span>
                </h2>
              </div>
              <div className="select">
                <select name="format" id="format">
                  <option disabled="">This Week</option>
                  <option selected="" value="This Week">
                    This Week
                  </option>
                </select>
              </div>
            </div>
            <div className="corp-reports-day">
              <img src="/images/2024/01/bg-1.png" />
              <div className="leads-day-box">
                <span>Sun</span>
                <span>Mon</span>
                <span>Tue</span>
                <span>Thu</span>
                <span>Fri</span>
                <span>Sat</span>
              </div>
            </div>
          </li>
          <li>
            <div className="total-leads-box">
              <div className="leads-text">
                <h4>Total Leads</h4>
                <h2>
                  2,444 <span>+8%</span>
                </h2>
              </div>
              <div className="select">
                <select name="format" id="format">
                  <option disabled="">This Week</option>
                  <option selected="" value="This Week">
                    This Week
                  </option>
                </select>
              </div>
            </div>
            <div className="corp-reports-day">
              <img src="/images/2024/01/bg-1.png" />
              <div className="leads-day-box">
                <span>Sun</span>
                <span>Mon</span>
                <span>Tue</span>
                <span>Thu</span>
                <span>Fri</span>
                <span>Sat</span>
              </div>
            </div>
          </li>
          <li>
            <div className="total-leads-box">
              <div className="leads-text">
                <h4>Total Cost</h4>
                <h2>
                  2,444 <span>+8%</span>
                </h2>
              </div>
              <div className="select">
                <select name="format" id="format">
                  <option disabled="">This Week</option>
                  <option selected="" value="This Week">
                    This Week
                  </option>
                </select>
              </div>
            </div>
            <div className="corp-reports-day">
              <img src="/images/2024/01/bg-1.png" />
              <div className="leads-day-box">
                <span>Sun</span>
                <span>Mon</span>
                <span>Tue</span>
                <span>Thu</span>
                <span>Fri</span>
                <span>Sat</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Graph;
