import React from "react";
import { FaInstagram, FaFacebookF, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsBrowserChrome } from "react-icons/bs";
import { encryptId } from "../../utils/cryptoUtils";
import { IoPersonAdd } from "react-icons/io5";
import LeadType from "../../common/LeadType";

const LeadList = ({
  adminLeadList,
  teamData,
  userId,
  toggleEdit,
  showEdit,
}) => {
  const AssignName = teamData.find((e) => e?.id == userId);
  return (
    <>
      <div className="scrollbar">
        {adminLeadList?.map((lead, index) => {
          return (
            <div className="leads-patient-doc-crm" key={index}>
              <div className="leads-patient-name">{lead?.patient_name}</div>
              <div className="leads-patient-country">{lead?.country}</div>
              <div className="leads-patient-source">
                {lead?.platform === "Whatsapp" ? (
                  <i>
                    <FaWhatsapp className="fa-whatsapp" fontSize={25} />
                  </i>
                ) : lead?.platform === "Landing Page" ? (
                  <i>
                    <BsBrowserChrome className="fab fa-website" fontSize={23} />
                  </i>
                ) : lead?.platform === "facebook" ? (
                  <i>
                    <FaFacebookF className="fa-facebook-f" />
                  </i>
                ) : (
                  <i>
                    <IoPersonAdd
                      className="fa fa-website"
                      style={{ background: "#737373" }}
                    />
                  </i>
                )}
              </div>
              <div className="leads-patient-speciality">
                <LeadType leadId={lead?.id} leadtype={lead?.lead_type} />
              </div>
              {lead?.patient_id ? (
                <div
                  className="leads-patient-report-status"
                  style={{ cursor: "pointer", color: "green" }}
                  onClick={(e) => toggleEdit(lead?.patient_id)}
                >
                  Edit Patient
                </div>
              ) : (
                <div className="leads-patient-report-status">Assign First</div>
              )}
              <div className="leads-patient-referred" style={{ color: "#000" }}>
                {AssignName?.name}
              </div>
              <div
                className="leads-patient-status"
                style={{
                  color: lead?.status === "Close Lead" ? "#c70000" : "green",
                }}
              >
                {lead?.status === "Close Lead" ? "Close Lead" : "Open Lead"}
              </div>
              {lead?.user_id && (
                <div className="leads-patient-view">
                  <Link
                    to={`/patient/${encryptId(lead?.id)}`}
                    data-popup-open="popup-7"
                    className="view"
                  >
                    View
                  </Link>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default LeadList;
