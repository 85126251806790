import React, { useState } from "react";
import LeadList from "./LeadList";
import Graph from "./Graph";
import Filter from "./Filter";
import LeftSlider from "../../Inc/LeftSlider";
import EditPatient from "./EditPatient";
import LeadSearch from "../../common/LeadSearch";

const Home = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);

  const toggleEdit = (id) => {
    setShowEdit(!showEdit);
    setSelectedPatientId(id);
  };

  return (
    <>
      <div className="medflick-mod">
        <LeftSlider path="home" />
        <div className="medflick-mod-right">
          <div className="scrollbar-1">
            <Graph />

            <div className="leads-search-doc">
              <h2>Leads</h2>
              <div className="leads-crm-search">
                <LeadSearch />
                <Filter />
              </div>
            </div>

            <div className="leads-patient-box">
              <div className="leads-patient-head">
                <div className="leads-patient-head-box1">Patient Name</div>
                <div className="leads-patient-head-box2">Country</div>
                <div className="leads-patient-head-box3">Source</div>
                <div className="leads-patient-head-box4">Lead Type</div>
                <div className="leads-patient-head-box5">Edit</div>
                <div className="leads-patient-head-box6">Assign To</div>
                <div className="leads-patient-head-box7">Status</div>
                <div className="leads-patient-head-box8">Action</div>
              </div>

              <LeadList toggleEdit={toggleEdit} showEdit={showEdit} />
            </div>
          </div>
        </div>
      </div>
      <EditPatient
        toggleMessage={toggleEdit}
        showMessage={showEdit}
        selectedPatientId={selectedPatientId}
      />
    </>
  );
};

export default Home;
