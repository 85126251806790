import React, { useEffect, useState, useRef } from "react";
import { FaInstagram, FaFacebookF, FaWhatsapp } from "react-icons/fa";
import { useGetLeadApi } from "../../context/getAllLeadContext";
import { Link } from "react-router-dom";
import { useWhatsappApi } from "../../context/whatsappContex";
import { useMedflickApi } from "../../context/medflickContext";
import { BsBrowserChrome } from "react-icons/bs";
import { encryptId } from "../../utils/cryptoUtils";
import { IoPersonAdd } from "react-icons/io5";
import LeadType from "../../common/LeadType";

const LeadList = ({ toggleEdit, showEdit }) => {
  const { getAllTreatment, treatmentsList } = useMedflickApi();
  const {
    allLead,
    getAllLead,
    loading,
    platformSelect,
    totalpage,
    setPage,
    page,
    displayData,
    query,
  } = useGetLeadApi();
  const [leadLoading, setLeadLoading] = useState(false);
  const scrollRef = useRef();

  const { teamData, fetchTeamData, postUserIdLeadId } = useWhatsappApi();

  useEffect(() => {
    getAllTreatment();
    getAllLead(page);
    fetchTeamData();
  }, [page]);

  const handleNextPage = () => {
    if (page < totalpage) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleTeamChange = async (e, leadId) => {
    const userId = e.target.value;
    setLeadLoading(true);
    try {
      const result = await postUserIdLeadId(userId, leadId);
      setLeadLoading(false);
      getAllLead(page);
    } catch (error) {
      console.error("Error posting data:", error);
      setLeadLoading(false);
    }
  };



  return (
    <>
      {(loading || leadLoading) && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}

      <div className="scrollbar">
        {displayData?.map((lead, index) => {
          const parsedFormData = JSON.parse(lead?.form_data);

          console.log("parsedFormData", parsedFormData);

          const fullNameData = parsedFormData?.find(
            (field) => field.name === "full_name"
          );

          const country = parsedFormData?.find(
            (field) => field.name === "country"
          );

          const countryFb = country?.values?.[0];

          const fullName = fullNameData?.values?.[0];

          return (
            <div
              className="leads-patient-doc-crm"
              key={index}
              style={{
                background: lead?.status === "Close Lead" ? "#A9A9A9" : "",
              }}
              title={lead?.status === "Close Lead" ? "This lead is closed" : ""}
            >
              <div className="leads-patient-name">
                {" "}
                {fullName ? fullName : lead?.patient_name}
              </div>
              <div className="leads-patient-country">
                {countryFb ? countryFb : lead?.country}
              </div>
              <div className="leads-patient-source">
                {lead?.platform === "Whatsapp" ? (
                  <i>
                    <FaWhatsapp className="fa-whatsapp" fontSize={25} />
                  </i>
                ) : lead?.platform === "Landing Page" ? (
                  <i>
                    <BsBrowserChrome className="fab fa-website" fontSize={23} />
                  </i>
                ) : lead?.platform === "Facebook" ? (
                  <i>
                    <FaFacebookF className="fa-facebook-f" />
                  </i>
                ) : (
                  <i>
                    <IoPersonAdd
                      className="fa fa-website"
                      style={{ background: "#737373" }}
                    />
                  </i>
                )}
              </div>
              <div className="leads-patient-speciality">
                <LeadType leadId={lead?.id} leadtype={lead?.lead_type} />
              </div>
              {lead?.patient_id ? (
                <div
                  className="leads-patient-report-status"
                  style={{ cursor: "pointer", color: "green" }}
                  onClick={(e) => toggleEdit(lead?.patient_id)}
                >
                  Edit Patient
                </div>
              ) : (
                <div className="leads-patient-report-status">Assign First</div>
              )}
              <div className="leads-patient-referred">
                <select
                  onChange={(e) => handleTeamChange(e, lead?.id)}
                  value={lead?.user_id || " "}
                >
                  <option value=" ">Assign Lead</option>
                  {teamData.map((team) => (
                    <option key={team?.id} value={team?.id}>
                      {team?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="leads-patient-status"
                style={{
                  color: lead?.status === "Close Lead" ? "#c70000" : "green",
                }}
              >
                {lead?.status === "Close Lead" ? "Close Lead" : "Open Lead"}
              </div>

              {lead?.user_id && (
                <div className="leads-patient-view">
                  <Link
                    to={`/patient/${encryptId(lead?.id)}`}
                    data-popup-open="popup-7"
                    className="view"
                  >
                    View
                  </Link>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {!query && (
        <>
          <button onClick={handlePrevPage} disabled={page <= 1}>
            Prev Page
          </button>
          <button onClick={handleNextPage} disabled={page >= totalpage}>
            Next Page
          </button>
          <p>
            Page {page} of {totalpage}
          </p>
        </>
      )}
    </>
  );
};

export default LeadList;
