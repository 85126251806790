import CryptoJS from "crypto-js";

const secretKey = "asdvghfsa75sdafdvfysva5rasgvwqevhgsvytf";

export const encryptId = (id) => {
  if (!id) {
    console.error("Invalid ID for encryption:", id); // Add logging for debugging
    return null;
  }

  const encrypted = CryptoJS.AES.encrypt(id.toString(), secretKey).toString();
  return encrypted.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

export const decryptId = (encryptedId) => {
  const correctedId = encryptedId.replace(/-/g, "+").replace(/_/g, "/");
  const bytes = CryptoJS.AES.decrypt(correctedId, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
