import React, { createContext, useState, useContext } from "react";
import {
  axiosMedflickInstance,
  axiosInstance,
} from "../axiosInstance/axiosInstance";

const GetMedflickDataContext = createContext();

export const MedflickProvider = ({ children }) => {
  const [treatmentsList, setTreatmentsList] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [treatmentUploadList, setTreatmentUploadList] = useState([]);

  const getAllTreatment = async () => {
    try {
      const response = await axiosMedflickInstance.get("/treatments");
      setTreatmentsList(response.data.data.treatments);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getAllUploadedTreatment = async () => {
    try {
      const response = await axiosInstance.get("/SpecialityList");
      setTreatmentUploadList(response.data.specialityList);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getAllHospitals = async () => {
    try {
      const response = await axiosMedflickInstance.get("/search");
      setHospitals(response.data.searchData.hospitals);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getHospitalNameById = (hospitalId, hospitals) => {
    const hospital = hospitals.find((h) => h.id === parseInt(hospitalId));
    return hospital ? hospital.name : "Hospital Not Found";
  };

  console.log("hospitals", hospitals);

  return (
    <GetMedflickDataContext.Provider
      value={{
        treatmentsList,
        getAllTreatment,
        getAllHospitals,
        hospitals,
        getAllUploadedTreatment,
        treatmentUploadList,
        getHospitalNameById,
      }}
    >
      {children}
    </GetMedflickDataContext.Provider>
  );
};

export const useMedflickApi = () => {
  return useContext(GetMedflickDataContext);
};
