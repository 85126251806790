import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useMedflickApi } from "../../context/medflickContext";

const UploadOpinion = ({
  patientId,
  userId,
  opinions,
  patientName,
  getBackgroundColor,
  leadDetail,
  getHospitalNameById,
  hospitals,
}) => {
  return (
    <>
      <div className="patient-dashboard-reports">
        <h2>
          Upload Opinion{" "}
          <Link to={`/upload-opinion/${userId}/${patientId}`}>
            Upload Opinion
          </Link>
        </h2>
        {opinions?.length > 0 ? (
          <div className="opinion-table-container">
            <table className="opinion-table">
              <thead>
                <tr>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    ID
                  </th>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    Patient Name
                  </th>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    Hospital Name
                  </th>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    Report
                  </th>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    Created At
                  </th>
                </tr>
              </thead>
              <tbody>
                {opinions?.map((opinion) => (
                  <tr key={opinion.id}>
                    <td>{opinion.id}</td>
                    <td>{patientName}</td>
                    <td>
                      {getHospitalNameById(opinion.hospital_id, hospitals)}
                    </td>
                    <td>
                      <a
                        href={opinion.reports}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View File
                      </a>
                    </td>
                    <td>{new Date(opinion.created_at).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No uploaded opinions available.</p>
        )}
      </div>
    </>
  );
};

export default UploadOpinion;
