import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useRoutes,
  useNavigate,
} from "react-router-dom";
import HomePage from "./components/HomePage";
import PatientDetail from "../page/patientDetails/PatientDetail";
import Login from "../page/login/Login";
import ProtectedRoute from "../middleware/ProtectedRoute";
import Manish from "./Manish";
import CreateUser from "../page/createuser/CreateUser";
import AdminLeadList from "../admin/leadList/AdminLeadList";
import UserList from "../page/userList/UserList";
import AddPatient from "../page/add-patient/AddPatient";
import UploadPassport from "../page/upload-passport/UploadPassport";
import UploadOpinion from "../page/upload-opinion/UploadOpinion";
import UpdateProfile from "../page/update-profile/UpdateProfile";
import WhatsappTemplate from "../page/whatsappTemplate/WhatsappTemplate";
import SendVisa from "../page/sendVisa/SendVisa";
import UploadVisa from "../page/upload-visa/UploadVisa";
import TicketSend from "../page/ticket-send/TicketSend";
import SendOpinion from "../page/send-opinion/SendOpinion";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute allowedRoles={["1"]} requireLogin={true}>
              <HomePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/manish"
          element={
            <ProtectedRoute allowedRoles={["1"]} requireLogin={true}>
              <Manish />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-user"
          element={
            <ProtectedRoute allowedRoles={["1"]} requireLogin={true}>
              <CreateUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-lead-list"
          element={
            <ProtectedRoute allowedRoles={["2"]} requireLogin={true}>
              <AdminLeadList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-list"
          element={
            <ProtectedRoute allowedRoles={["1"]} requireLogin={true}>
              <UserList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-patient"
          element={
            <ProtectedRoute allowedRoles={["1", "2"]} requireLogin={true}>
              <AddPatient />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload-passport"
          element={
            <ProtectedRoute allowedRoles={["1", "2"]} requireLogin={true}>
              <UploadPassport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/update-profile"
          element={
            <ProtectedRoute requireLogin={true}>
              <UpdateProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload-opinion/:userId/:patientId"
          element={
            <ProtectedRoute allowedRoles={["1", "2"]} requireLogin={true}>
              <UploadOpinion />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload-visa/:userIdE/:patientIdE/:specE"
          element={
            <ProtectedRoute allowedRoles={["1", "2"]} requireLogin={true}>
              <UploadVisa />
            </ProtectedRoute>
          }
        />
        <Route
          path="/send-opinion/:userIdE/:patientIdE"
          element={
            <ProtectedRoute allowedRoles={["1", "2"]} requireLogin={true}>
              <SendOpinion />
            </ProtectedRoute>
          }
        />
        <Route
          path="/send-visa/:userIdE/:patientIdE/:specE"
          element={
            <ProtectedRoute allowedRoles={["1", "2"]} requireLogin={true}>
              <SendVisa />
            </ProtectedRoute>
          }
        />
        <Route
          path="/send-ticket/:userIdE/:patientIdE/:specE"
          element={
            <ProtectedRoute allowedRoles={["1", "2"]} requireLogin={true}>
              <TicketSend />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/patient/:did"
          element={
            <ProtectedRoute requireLogin={true}>
              <PatientDetail />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/whatsapp-template/:did"
          element={
            <ProtectedRoute requireLogin={true}>
              <WhatsappTemplate />
            </ProtectedRoute>
          }
        ></Route>
        <Route exact path="/login" element={<Login />}></Route>
      </Routes>
    </>
  );
}

export default App;
