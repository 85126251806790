import React, { useEffect, useState } from "react";
import "./uploadPassport.css";
import LeftSlider from "../../Inc/LeftSlider";
import { axiosInstance } from "../../axiosInstance/axiosInstance";

const UploadPassport = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [passportImage, setPassportImage] = useState(null);
  const [numAttendants, setNumAttendants] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [patientEmbassy, setPatientEmbassy] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");

  const [patientId, setPatientId] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const patienId = localStorage.getItem("patientId");
    const userId = localStorage.getItem("userrrId");

    if (patienId) {
      setPatientId(patienId);
    }
    if (userId) {
      setUserId(userId);
    }
  }, []);

  //   const [attendantNumbers, setAttendantNumbers] = useState(["", "", ""]);
  //   const [attendantEmbassyNames, setAttendantEmbassyNames] = useState([
  //     "",
  //     "",
  //     "",
  //   ]);
  //   const [attendantImages, setAttendantImages] = useState([null, null, null]);

  //   const handleAttendantNumberChange = (index, value) => {
  //     const updatedNumbers = [...attendantNumbers];
  //     updatedNumbers[index - 1] = value;
  //     setAttendantNumbers(updatedNumbers);
  //   };

  //   const handleAttendantEmbassyNameChange = (index, value) => {
  //     const updatedNames = [...attendantEmbassyNames];
  //     updatedNames[index - 1] = value;
  //     setAttendantEmbassyNames(updatedNames);
  //   };

  //   const handleAttendantImageChange = (index, event) => {
  //     const updatedImages = [...attendantImages];
  //     updatedImages[index - 1] = event.target.files[0];
  //     setAttendantImages(updatedImages);
  //   };

  const [attendantNumber1, setAttendantNumber1] = useState("");
  const [attendantNumber2, setAttendantNumber2] = useState("");
  const [attendantNumber3, setAttendantNumber3] = useState("");

  const [attendantImage1, setAttendantImage1] = useState(null);
  const [attendantImage2, setAttendantImage2] = useState(null);
  const [attendantImage3, setAttendantImage3] = useState(null);

  const [attendantEmbassyNames1, setAttendantEmbassyNames1] = useState("");
  const [attendantEmbassyNames2, setAttendantEmbassyNames2] = useState("");
  const [attendantEmbassyNames3, setAttendantEmbassyNames3] = useState("");

  const [attendentName1, setAttendentName1] = useState("");
  const [attendentName2, setAttendentName2] = useState("");
  const [attendentName3, setAttendentName3] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: "",
    phone: "",
    email: "",
    query: "",
    captcha: "",
  });

  const handleAttendantNameChange = (index, value) => {
    switch (index) {
      case 1:
        setAttendentName1(value);
        break;
      case 2:
        setAttendentName2(value);
        break;
      case 3:
        setAttendentName3(value);
        break;
      default:
        break;
    }
  };

  const handleAttendantNumberChange = (index, value) => {
    switch (index) {
      case 1:
        setAttendantNumber1(value);
        break;
      case 2:
        setAttendantNumber2(value);
        break;
      case 3:
        setAttendantNumber3(value);
        break;
      default:
        break;
    }
  };

  const handleAttendantEmbassyNameChange = (index, value) => {
    switch (index) {
      case 1:
        setAttendantEmbassyNames1(value);
        break;
      case 2:
        setAttendantEmbassyNames2(value);
        break;
      case 3:
        setAttendantEmbassyNames3(value);
        break;
      default:
        break;
    }
  };

  const handleAttendantImageChange = (index, event) => {
    const imageFile = event.target.files[0];

    switch (index) {
      case 1:
        setAttendantImage1(imageFile);
        break;
      case 2:
        setAttendantImage2(imageFile);
        break;
      case 3:
        setAttendantImage3(imageFile);
        break;
      default:
        break;
    }
  };

  const clearFormFields = () => {
    setCountry("");
    setCity("");
    setPatientEmbassy("");
    setSelectedFile(null);
    setAttendantImage1(null);
    setAttendantImage2(null);
    setAttendantImage3(null);
    setAttendantNumber1("");
    setAttendantNumber2("");
    setAttendantNumber3("");
    setPassportNumber("");
    setAttendentName1("");
    setAttendentName2("");
    setAttendentName3("");
    setAttendantEmbassyNames1("");
    setAttendantEmbassyNames2("");
    setAttendantEmbassyNames3("");
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    setFormErrors({
      name: "",
      phone: "",
      email: "",
      query: "",
      captcha: "",
    });

    let isValid = true;

    if (isValid) {
      const data = {
        country: country,
        patient_id: patientId,
        user_id: userId,
        city: city,
        patient_embassy_name: patientEmbassy,
        passport_number: passportNumber,
        upload_passport: selectedFile,
        upload_attandant_passport1: attendantImage1,
        upload_attandant_passport2: attendantImage2,
        upload_attandant_passport3: attendantImage3,
        attandant_passport_number1: attendantNumber1,
        attandant_passport_number2: attendantNumber2,
        attandant_passport_number3: attendantNumber3,
        attandant_embassy_name1: attendantEmbassyNames1,
        attandant_embassy_name2: attendantEmbassyNames2,
        attandant_embassy_name3: attendantEmbassyNames3,
        attandant_name1: attendentName1,
        attandant_name2: attendentName2,
        attandant_name3: attendentName3,
      };

      //   const attendantEmbassyNamesStr = JSON.stringify(
      //     attendantEmbassyNames.filter((name) => name !== "")
      //   );
      //   const attendantNumbersStr = JSON.stringify(
      //     attendantNumbers.filter((number) => number !== "")
      //   );
      //   const attendantImagesArray = attendantImages.filter(
      //     (image) => image !== null
      //   );

      const apiEndpoint = `/uploadpassport`;

      setIsLoading(true);

      axiosInstance
        .post(apiEndpoint, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          alert("Passport Upload Successfully");
          clearFormFields();
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleAddAttendant = () => {
    if (numAttendants < 3) {
      setNumAttendants(numAttendants + 1);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const fileDisplay = selectedFile ? (
    <div className="file__value" onClick={() => setSelectedFile(null)}>
      <div className="file__value--text">{selectedFile.name}</div>
      <div className="file__value--remove" data-id={selectedFile.name}></div>
    </div>
  ) : null;

  const fileDisplay1 = attendantImage1 ? (
    <div className="file__value" onClick={() => setAttendantImage1(null)}>
      <div className="file__value--text">{attendantImage1.name}</div>
      <div className="file__value--remove" data-id={attendantImage1.name}></div>
    </div>
  ) : null;

  const fileDisplay2 = attendantImage2 ? (
    <div className="file__value" onClick={() => setAttendantImage2(null)}>
      <div className="file__value--text">{attendantImage2.name}</div>
      <div className="file__value--remove" data-id={attendantImage2.name}></div>
    </div>
  ) : null;

  const fileDisplay3 = attendantImage3 ? (
    <div className="file__value" onClick={() => setAttendantImage3(null)}>
      <div className="file__value--text">{attendantImage3.name}</div>
      <div className="file__value--remove" data-id={attendantImage3.name}></div>
    </div>
  ) : null;

  return (
    <>
      {isLoading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <section id="medflick-mod">
        <div className="medflick-mod">
          <LeftSlider />
          <div className="medflick-mod-right">
            <div className="scrollbar-1">
              <div className="query-form-page">
                <div className="medflick-visa-right">
                  <h2>Upload Passsport</h2>
                  <div className="visa-form">
                    <div className="visa-form-box">
                      <label>Country *</label>
                      <input
                        placeholder="text"
                        autocomplete="off"
                        type="text"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="visa-form">
                    <div className="visa-form-box">
                      <label>Patient Passport Number</label>
                      <div className="visa-form1">
                        <input
                          placeholder="XXXXXXXXXXXX"
                          type="text"
                          value={passportNumber}
                          onChange={(e) => setPassportNumber(e.target.value)}
                        />
                        <div className="medical-report-all">
                          {fileDisplay}
                          {!selectedFile && (
                            <div>
                              <button className="medical-report-file">
                                Upload Passport
                              </button>
                              <input
                                type="file"
                                name="file"
                                onChange={handleFileChange}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="visa-form">
                    <div className="visa-form-box">
                      <label>Patient Embassy Name *</label>
                      <input
                        placeholder="text"
                        autocomplete="off"
                        type="text"
                        value={patientEmbassy}
                        onChange={(e) => setPatientEmbassy(e.target.value)}
                      />
                      <div style={{ color: "red" }}>
                        {formErrors.patientEmbassy}
                      </div>
                    </div>
                  </div>
                  <div className="visa-form">
                    <div className="visa-form-box">
                      <label>Attendant Passport Number</label>
                      <div className="visa-form2">
                        <input
                          placeholder="XXXXXXXXXXX"
                          type="text"
                          value={attendantNumber1}
                          onChange={(e) =>
                            handleAttendantNumberChange(1, e.target.value)
                          }
                        />
                        <div className="medical-report-all">
                          {fileDisplay1}
                          {!attendantImage1 && (
                            <>
                              <button
                                type="button"
                                className="medical-report-file"
                              >
                                Upload Passport
                              </button>
                              <input
                                type="file"
                                name="attendantImage1"
                                onChange={(e) =>
                                  handleAttendantImageChange(1, e)
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="visa-form-box">
                        <label>Attendant Name *</label>
                        <input
                          placeholder="Email"
                          autocomplete="off"
                          type="text"
                          value={attendentName1}
                          onChange={(e) =>
                            handleAttendantNameChange(1, e.target.value)
                          }
                        />
                      </div>

                      {numAttendants >= 2 && (
                        <>
                          <label>Attendant Passport Number (2)</label>
                          <div className="visa-form2">
                            <input
                              type="text"
                              placeholder="XXXXXXXXXXX"
                              value={attendantNumber2}
                              onChange={(e) =>
                                handleAttendantNumberChange(2, e.target.value)
                              }
                            />
                            <div className="medical-report-all">
                              {fileDisplay2}
                              {!attendantImage2 && (
                                <>
                                  <button
                                    type="button"
                                    className="medical-report-file"
                                  >
                                    Upload Passport
                                  </button>
                                  <input
                                    type="file"
                                    name="attendantImage2"
                                    onChange={(e) =>
                                      handleAttendantImageChange(2, e)
                                    }
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="visa-form-box">
                            <label>Attendant Name(2) *</label>
                            <input
                              placeholder="Email"
                              autocomplete="off"
                              type="text"
                              value={attendentName2}
                              onChange={(e) =>
                                handleAttendantNameChange(2, e.target.value)
                              }
                            />
                          </div>
                        </>
                      )}

                      {/* Attendant 3 */}
                      {numAttendants >= 3 && (
                        <>
                          <label>Attendant Passport Number (3)</label>
                          <div className="visa-form2">
                            <input
                              type="text"
                              placeholder="XXXXXXXXXXX"
                              value={attendantNumber3}
                              onChange={(e) =>
                                handleAttendantNumberChange(3, e.target.value)
                              }
                            />
                            <div className="medical-report-all">
                              {fileDisplay3}
                              {!attendantImage3 && (
                                <>
                                  <button
                                    type="button"
                                    className="medical-report-file"
                                  >
                                    Upload Passport
                                  </button>
                                  <input
                                    type="file"
                                    name="attendantImage3"
                                    onChange={(e) =>
                                      handleAttendantImageChange(3, e)
                                    }
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="visa-form-box">
                            <label>Attendant Name(3) *</label>
                            <input
                              placeholder="Email"
                              autocomplete="off"
                              type="text"
                              value={attendentName3}
                              onChange={(e) =>
                                handleAttendantNameChange(3, e.target.value)
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {numAttendants < 3 && (
                    <div className="visa-form-box">
                      <a
                        onClick={handleAddAttendant}
                        style={{ color: "#ff6800" }}
                      >
                        Add Attendant +
                      </a>
                    </div>
                  )}
                  <div className="visa-form-box">
                    <button
                      type="submit"
                      name="en"
                      className="visa-submit"
                      onClick={handleFormSubmit}
                    >
                      {" "}
                      Submit Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UploadPassport;
