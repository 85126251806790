import React from "react";

const VisaSubmisiionDate = ({ visaData }) => {
  return (
    <>
      <div className="visa-data-list">
        {visaData.length > 0 ? (
          visaData.map((item) => (
            <div className="visa-data-item" key={item.id}>
              <p>
                <strong>Submission Date:</strong>{" "}
                {item.sumission_date ? item.sumission_date : "Not Submitted"}
              </p>
              <p>
                <strong>Receive Date:</strong>{" "}
                {item.receive_date ? item.receive_date : "Not Received"}
              </p>
              <p>
                <strong>Created At:</strong>{" "}
                {new Date(item.created_at).toLocaleDateString()}
              </p>
            </div>
          ))
        ) : (
          <p>No data available</p>
        )}
      </div>
    </>
  );
};

export default VisaSubmisiionDate;
