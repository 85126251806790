import React, { useEffect, useState } from "react";
import "./UploadOpinion.css";
import { useMedflickApi } from "../../context/medflickContext";
import Select from "react-select";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import { useParams } from "react-router-dom";

const UploadOpinion = () => {
  const { userId, patientId } = useParams();
  const { getAllHospitals, hospitals } = useMedflickApi();
  const [hospitalId, setHospitalId] = useState("");
  const [opinion, setOpinion] = useState("");
  const [pdfUpload, setPdfUpload] = useState("");
  const [imgUpload, setImgUpload] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllHospitals();
  }, []);

  const hospitalOptions = hospitals.map((hospital) => ({
    value: hospital.id,
    label: hospital.name,
  }));

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;

      if (fileType === "application/pdf") {
        setPdfUpload(file);
        setImgUpload(""); // Reset the image upload state
      } else {
        alert("Unsupported file type. Please upload a PDF file.");
        setPdfUpload(""); // Reset the PDF upload state
      }
    }
  };

  const handleTextChange = (e) => {
    setOpinion(e.target.value);
  };

  const handleSelectChange = (selectedOption) => {
    const { value, label } = selectedOption;
    setHospitalId(selectedOption);
  };

  const handleOpinion = async () => {
    setLoading(true);
    if (!pdfUpload) {
      alert("Please upload a pdf file.");
      setLoading(false);
      return;
    }
    try {
      const data = {
        hospital_id: hospitalId?.value,
        patient_id: patientId,
        user_id: userId,
        description: opinion,
        reports_pdf: pdfUpload,
        reports_image: imgUpload,
      };
      const response = await axiosInstance.post(
        "/hospital_opinion_by_medflick",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("Opinion Upload Succesfully");
      setHospitalId("");
      setOpinion("");
      setPdfUpload("");
      setImgUpload("");
    } catch (err) {
      console.log({ apiError: err.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="upload-opinion-container">
      <h2>Upload Your Opinion</h2>

      <div className="upload-option">
        <label htmlFor="file-upload" className="file-upload-label">
          Select Hospital
        </label>
        <Select
          options={hospitalOptions}
          placeholder="Select Treatment"
          onChange={handleSelectChange}
          value={hospitalId}
        />
      </div>
      <div className="upload-option">
        <label htmlFor="file-upload" className="file-upload-label">
          Upload File:
        </label>
        <input type="file" id="file-upload" onChange={handleFileUpload} />
      </div>

      <div className="long-text-option">
        <label htmlFor="opinion-text" className="opinion-text-label">
          Or, Write Your Opinion:
        </label>
        <textarea
          id="opinion-text"
          value={opinion}
          onChange={handleTextChange}
          placeholder="Write your opinion here..."
        ></textarea>
      </div>

      <button
        className="submit-opinion-button"
        onClick={handleOpinion}
        disabled={loading}
      >
        {loading ? "Loading..." : "Submit"}
      </button>
    </div>
  );
};

export default UploadOpinion;
