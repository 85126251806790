import React, { useEffect, useState } from "react";

const Header = () => {
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const name = localStorage.getItem("userName");
    const role = localStorage.getItem("userRole");
    if (name) {
      setUserName(name);
    }
    if (role) {
      setUserRole(role);
    }
  }, []);
  return (
    <>
      <div className="head-top">
        <div className="head-top-left">
          {" "}
          <img src="images/2024/01/logo.png" />{" "}
        </div>
        <div className="head-top-right">
          {" "}
          <h1>
            Hello {userName} ,
            <br /> Role:{" "}
            {userRole === "1"
              ? "Superadmin"
              : userRole === "2"
              ? "Admin"
              : userRole === "3"
              ? "Manager"
              : "agent"}
          </h1>
        </div>
      </div>
    </>
  );
};

export default Header;
